@import "loader";

:root {
    // Variáveis de configuração de cores
    --primary-color: #ff0000;
    --primary-hover-color: #ff5c5c;
    --selection-color: #c02929;
}

body {
    margin: 0;
    padding: 0;
}

html {
    font-size: 10px;

    p,
    button,
    span,
    a,
    label {
        font-size: 1.4rem;
    }

    h1 {
        font-size: 4rem;
    }

    h2 {
        font-size: 3.2rem;
    }

    h3 {
        font-size: 2.8rem;
    }

    h4 {
        font-size: 2.4rem;
    }

    h5 {
        font-size: 2rem;
    }

    h6 {
        font-size: 1.6rem;
    }
}

.red {
    color: red;
}

.pointer {
    cursor: pointer;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(255, 255, 255) inset !important;
    box-shadow: 0 0 0 30px rgb(255, 255, 255) inset !important;
}

// Alterando a cor da seleção do texto
::-moz-selection {
    color: white;
    background: var(--selection-color);
}
  
::selection {
    color: white;
    background: var(--selection-color);
}

$my-red: (
    50: #ff0000,
    100: #ff0000,
    200: #ff0000,
    300: #ff0000,
    400: #ff0000,
    500: #ff0000,
    600: #ff0000,
    700: #ff0000,
    800: #ff0000,
    900: #ff0000,
    A100: #ff0000,
    A200: #ff0000,
    A400: #ff0000,
    A700: #ff0000,
    contrast: (
        50: #ff0000,
        100: #ff0000,
        200: #ff0000,
        300: #ff0000,
        400: #ff0000,
        500: #ff0000,
        600: #ff0000,
        700: #ff0000,
        800: #ff0000,
        900: #ff0000,
        A100: #ff0000,
        A200: #ff0000,
        A400: #ff0000,
        A700: #ff0000,
    ),
);

@import "~@angular/material/theming";
@include mat-core();
$my-app-primary: mat-palette($my-red, 900);
$my-app-accent: mat-palette($mat-pink, A200, A100, A400);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent);

@include angular-material-theme($my-app-theme);

@import "~ng-zorro-antd/src/ng-zorro-antd.min.css";
@import "~ng-zorro-antd/style/index.min.css";

// Define o estilo padrão para a modal do NGZorro
.ant-modal-content {
    .ant-modal-close {
        .ant-modal-close-x {
            font-size: 2.2rem;
            color: white;

            &:hover {
                color: var(--primary-color);
                transition: 0.5s;
            }
        }
    }

    .ant-modal-header {
        background-color: #323643;
    
        .ant-modal-title {
            color: white;
            font-size: 2rem;
        }
    }

    .ant-modal-footer {
        .ant-btn {
           
            &:hover, &:active, &:focus {
                border-color: var(--primary-color);    
                color: var(--primary-color);
            }
        }

        .ant-btn-primary {
            background-color: var(--primary-color);
            border-color: var(--primary-color);

            &:hover, &:active, &:focus {
                color: white;
                background-color: var(--primary-hover-color);
            }
        }
    }
}