.loader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.8);

    #loading-bar-spinner.spinner {
        left: 50%;
        margin-left: -2rem;
        top: 50%;
        margin-top: -2rem;
        position: absolute;
        z-index: 20 !important;
        animation: loading-bar-spinner 400ms linear infinite;
    }
    
    #loading-bar-spinner.spinner .spinner-icon {
        width: 4rem;
        height: 4rem;
        border:  solid 0.4rem transparent;
        border-top-color:  #ff0000 !important;
        border-left-color: #ff0000 !important;
        border-radius: 50%;
    }
    
    @keyframes loading-bar-spinner {
      0%   { transform: rotate(0deg);   transform: rotate(0deg); }
      100% { transform: rotate(360deg); transform: rotate(360deg); }
    }
}